import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ADEPT_USER_OFFERS } from 'Consts/routes';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Button from 'Components/layout/Button';

export default class AdeptUserOfferRemoval extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            remove: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
    };
    static defaultProps = {
        data: null,
    };

    onRemove = () => {
        const { actions, data, history } = this.props;

        actions.remove({ id: data.id }) 
            .then(response => {
                history.push(ADEPT_USER_OFFERS.path);
                toast('Zamówienie zostało usunięte');
            })
            .catch(error => {
                toast('Wystąpił błąd, spróbuj ponownie poźniej');
                Logger.error('[AdeptUserOffersEditor] Failed to paymnet', error);
                this.setState({
                    isPending: false,
                    error: true,
                });
            });
    }

    render() {
        const { data } = this.props;
        
        if (!data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-user-offers-removal"
                styles={require('./styles')}
            >
                <p className="description">Zamówienie zostanie usunięte.</p>
                <Button
                    onClick={this.onRemove}
                    style='gradient'    
                > 
                    Potwierdzam
                </Button>
            </StyledComponent>
        );
    }
}