import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    color: ${variables.dpc_fontDark};
    width: 40vw;

    .admin-element-editor {
        width: 40vw;

        .editor-wrapper {
            .forms-wrapper { 
                .form-wrapper { 
                    width: 33vw;
                    border: 0;
                    padding-top: 2em;
                    margin-top: 0;
                }

                .admin-form-generator {
                    .form-elements {
                        .form-element {
                            .input-external-wrapper {
                                .input-label {
                                    color: ${variables.dpc_fontDark};
                                }
                            }
                        }
                    }
                }             
            }
        }
    }
}
`;
