import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ADEPT_DATA } from 'Consts/routes';

import Logger from 'Services/Logger';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdeptMeasurementsConfigNewType extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            newType: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},
    };

    onSubmit = formState => {
        const { actions, data, history } = this.props;

        return actions.newType({
            ...formState,
        })
            .then(response => {
                history.push(
                    withVariables(ADEPT_DATA.path, {}, { tab: 'measurementsData' }),
                );
            })
            .catch(error => {
                if (Object.keys(error.payload.validationErrors).length == 0) {
                    toast('Wystąpił błąd, spróbuj ponownie później');
                }

                throw error;
            });
    }

    render() {
        const { data, location, history } = this.props;
        const { formState } = this.state;
        
        if (!data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-measurements-new-type"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa',
                            required: true,
                        }, {
                            type: 'input',
                            name: 'unit',
                            label: 'Jednostka',
                            required: true,
                        }, {
                            type: 'switch',
                            name: 'enabled',
                            label: 'Włączony/wyłączony',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}