import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`   
        margin-top: 1em;

        .measurement-config-box-footer {
            margin-top: 1em;
            padding-top: 1em;
            border-top: .1em solid #333;

            .measurement-config-box-footer-control {
                cursor: pointer;
                color: #eee;
                font-size: 1em;
                font-weight: 300;
                display: block;
                text-align: center;

                &:hover {
                    color: white;
                }
            }
        }

        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    width: 100% !important;
                    padding-right: 0 !important;

                    .form-wrapper {
                        padding: 0;
                        border: none;
                    }
                }
                .data-wrapper {
                    display: none;
                }
            }
        }

        @media (max-width: ${variables.tabletS}) {}
    `;
