import { connect } from 'react-redux';
import Component from './component';

import {
    create, 
    removeType,
} from 'Redux/modules/adept/measurementsConfig/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            create: dispatch(create),
            removeType: dispatch(removeType),
        },
    }),
)(Component);