import { connect } from 'react-redux';
import Component from './component';

import {
    newType,
} from 'Redux/modules/adept/measurements/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            newType: dispatch(newType),
        },
    }),
)(Component);