export const MEASUREMENTS_CONFIG = {
    belt: 'Pas',
    bicepsLeft: 'Lewy biceps',
    bicepsRight: 'Prawy biceps',
    calfLeft: 'Lewa łydka',
    calfRight: 'Prawa łydka',
    chest: 'Klatka',
    hips: 'Biodra',
    thighLeft: 'Lewe udo',
    thighRight: 'Prawe udo',
    weight: 'Waga',
    waist: 'Talia',
};
