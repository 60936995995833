import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { MEASUREMENTS_CONFIG } from 'Consts/measurementConfig';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import Button from 'Components/layout/Button';

export default class AdeptMeasurementsConfigEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            removeType: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},  //eslint-disable-line react/destructuring-assignment
    };

    componentDidMount = () => {
        const { data } = this.props;
        
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data),
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = ({ fieldsConfig }) => {
        let formState = {};

        for (let index in fieldsConfig) {
            formState[index] = fieldsConfig[index].enabled;
        }

        return formState;
    }

    formStateToData = ( formState ) => {
        const { data } = this.props;

        let tmp = {};
        for (let index in formState) {
            let originalObject = data.fieldsConfig[index];
            tmp[index] = {
                ...originalObject,
                enabled: formState[index],
            };
        }
        
        return tmp;
    }

    onSubmit = formState => {
        return this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions } = this.props;

        return actions.create({
            fieldsConfig: this.formStateToData(formState),
        });
    }

    renderMeasurementConfigBoxes = ({ fieldsConfig }) => {  
        const { actions } = this.props;        
        const { formState } = this.state;
        return Object.keys(fieldsConfig).map(fieldConfigKey => {
            const fieldConfig = fieldsConfig[fieldConfigKey];

            let icon = require('../../../../theme/images/icons/' + fieldConfig.icon);
            return {
                type: 'switch',
                name: fieldConfigKey,
                label: fieldConfig.name,
                inputProps: {
                    value: formState[fieldConfigKey],
                },
                boxContent: {
                    imageSrc: icon,
                    footer: fieldConfig.custom && (
                        <div className="measurement-config-box-footer">
                            <a 
                                className="measurement-config-box-footer-control"
                                onClick={() => actions.removeType({ 
                                    key: fieldConfigKey,
                                }).then(
                                    toast('Typ pomiaru został usunięty')
                                )}
                            >
                                Usuń
                            </a>
                        </div>
                    ),
                },
            };
        });
    }

    render() {
        const { location, history, data } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-measurements-config-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    layout="box"
                    history={history}
                    location={location}
                    styleVersion={2}
                    header={(
                        <div className="measurements-config-editor-header">
                            <div className="measurements-config-editor-header-col col-right">
                                <Button
                                    className="measurements-editor-header-button"
                                    onClick={() => this.onSubmit}
                                    type='submit'
                                    form='editor'
                                    style='hollow'
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </div>
                    )}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        layout: 'box',
                        onSuccess: () =>  {},
                        submitVisible: false,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: this.renderMeasurementConfigBoxes(data),
                    }]}
                />
            </StyledComponent>
        );
    }
}